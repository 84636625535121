import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  backdrop: {
    status: "false",
  },
};

const backdropSlice = createSlice({
  name: "backdrop",
  initialState,
  reducers: {
    setBackdrop: (state, action) => {
      try {
        state.backdrop = action.payload;
      } catch (e) {
        alert("Failed");
      }
    },
  },
});

export const { setBackdrop } = backdropSlice.actions;

export default backdropSlice.reducer;

export const getBackdrop = (state: any) => state.backdrop.backdrop;
