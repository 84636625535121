import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../utils/client.js";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchPolice = createAsyncThunk("police/fetchPolice", async () => {
  const response = await client.get("./nearest_police");
  return response;
});

const policeSlice = createSlice({
  name: "police",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPolice.pending.type]: (state, action) => {
      state.status = "loading";
    },
    [fetchPolice.fulfilled.type]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchPolice.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export default policeSlice.reducer;

export const getPolice = (state: any) => state.police.data;
