import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getBackdrop } from "../../redux/reducers/backdropSlice";
import { setBackdrop } from "../../redux/reducers/backdropSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }),
);

function SimpleBackdrop() {
  const dispatch = useAppDispatch();
  let backdropStatus = useAppSelector(getBackdrop);

  const classes = useStyles();
  let [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(setBackdrop({ status: "false" }));
  };

  if (open == false && backdropStatus.status == "true") {
    setOpen(true);
  }
  if (open == true && backdropStatus.status == "false") {
    setOpen(false);
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default SimpleBackdrop;
