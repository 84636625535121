import { createAuthProvider } from "react-token-auth";

const TIMEOUT_MS = 10 * 60 * 1000; // 10 minutes

// Create a timeout promise
const timeout = (ms) => new Promise((_, reject) =>
  setTimeout(() => reject(new Error('Request timed out')), ms)
);

const customAuthFetch = async (input, init) => {
  const fetchPromise = originalAuthFetch(input, init); // Use originalAuthFetch here
  try {
    // Use Promise.race to trigger either fetch or timeout
    const response = await Promise.race([fetchPromise, timeout(TIMEOUT_MS)]);
    return response;
  } catch (error) {
    // Handle the timeout error
    console.error("Request failed: ", error.message);
    throw error;
  }
};

// Destructure and rename authFetch to originalAuthFetch
export const [useAuth, originalAuthFetch, login, logout] = createAuthProvider({
  accessTokenKey: "token",
  // Define the onUpdateToken function if needed
  // onUpdateToken: (token) => fetch('/api/refresh', {
  //     method: 'POST',
  //     body: JSON.stringify({ token: token.access_token }),
  //     headers: { 'Content-Type': 'application/json' }
  // }).then(r => r.json())
});

// Export customAuthFetch as the new authFetch
export { customAuthFetch as authFetch };
