import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  getRoute,
  getRoute2,
  getRoute3,
} from "../../redux/reducers/uploadedRouteSlice";
import {
  getRouteName,
  getRouteName2,
  getRouteName3,
} from "../../redux/reducers/uploadedRouteSlice";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

function MultipleRiskLineChart() {
  const dispatch = useAppDispatch();

  const route1 = useAppSelector(getRoute);
  const route2 = useAppSelector(getRoute2);
  const route3 = useAppSelector(getRoute3);

  const route1Name = useAppSelector(getRouteName);
  const route2Name = useAppSelector(getRouteName2);
  const route3Name = useAppSelector(getRouteName3);

  // Grabbing vulnerability scores for all 3 routes, pushing into individual arrays
  var route1scores: any[] = [];
  route1.features.forEach(function (value: any) {
    route1scores.push(value.properties.risk);
  });

  var route2scores: any[] = [];
  route2.features.forEach(function (value: any) {
    route2scores.push(value.properties.risk);
  });

  var route3scores: any[] = [];
  route3.features.forEach(function (value: any) {
    route3scores.push(value.properties.risk);
  });

  var dataArray = [];
  // Getting length of longest route array
  var longestArray = Math.max(
    route1scores.length,
    route2scores.length,
    route3scores.length
  );

  // Filling the arrays to the same size
  for (var i = route1scores.length; i < longestArray; i++) {
    route1scores.push(null);
  }
  for (var i = route2scores.length; i < longestArray; i++) {
    route2scores.push(null);
  }
  for (var i = route3scores.length; i < longestArray; i++) {
    route3scores.push(null);
  }

  // Building chart data
  for (var i = 0; i < longestArray; i++) {
    dataArray.push({
      linkID: i,
      [route1Name]: route1scores[i],
      [route2Name]: route2scores[i],
      [route3Name]: route3scores[i],
    });
  }

  const pageSize = 100; // Number of entries per page
  const [currentPage, setCurrentPage] = useState(0);
  const paginatedData = dataArray.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  const needsPagination = dataArray.length > pageSize;

  const divStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
    overflow: 'hidden',
    // height: '100%',
    padding: '16px', // Added padding all around
  };

  const chartStyle: React.CSSProperties = {
    height: '268px',
    width: '100%',
    overflow: 'hidden',
  };

  function nextPage() {
    if ((currentPage + 1) * pageSize < dataArray.length) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevPage() {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  return (
    <div style={divStyle}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10px", height: "40px", width: '100%' }}>
        <button onClick={prevPage} disabled={currentPage === 0} style={{ cursor: "pointer" }}>
          <ArrowBackIcon style={{ fontSize: "14px" }} />
        </button>
        <button onClick={nextPage} disabled={(currentPage + 1) * pageSize >= dataArray.length} style={{ cursor: "pointer" }}>
          <ArrowForwardIcon style={{ fontSize: "14px" }} />
        </button>
      </div>
      <div style={chartStyle}>
        <ResponsiveContainer box-sizing="border-box" width="100%" debounce={1}>
          <LineChart data={paginatedData}>
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="linkID" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={route1Name} stroke="#82ca9d" />
            <Line type="monotone" dataKey={route2Name} stroke="#8884d8" />
            <Line type="monotone" dataKey={route3Name} stroke="#ff7300" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={{ marginTop: '10px' }}>
        {/* Any additional content can go here */}
      </div>
    </div>
  );
}

export { MultipleRiskLineChart };
