import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //ORNL, Los Alamons, Lawrence Livermore, Sandia
  destinations: [
    { id: 99996, name: "1 Bethel Valley Rd, Oak Ridge, TN" },
    { id: 99992, name: "18050 Von Karman Ave, Irvine, CA" },
    { id: 99993, name: "240 Technology Dr, Idaho Falls, ID" },
    { id: 99994, name: "4021 National Parks Hwy, Carlsbad, NM" },
    { id: 99995, name: "2401 River Rd, Schenectady, NY" },
    // { id: 99997, name: "115 N Main St, Carlsbad, NM" },
    { id: 99998, name: "7000 East Ave, Livermore, CA" },
    { id: 99999, name: "1611 Innovation Pkwy SE, Albuquerque, NM" },
  ],
};

const destinationsSlice = createSlice({
  name: "destinations",
  initialState,
  reducers: {
    setDestinations: (state, action) => {
      try {
        state.destinations = action.payload;
      } catch (e) {
        alert("Failed to load destinations. Please try again.");
      }
    },
  },
});

export const { setDestinations } = destinationsSlice.actions;

export default destinationsSlice.reducer;

export const getDestinations = (state: any) => state.destinations.destinations;
