import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   cellCoverageSlider: 5,
//   incidentsSlider: 5,
//   populationSlider: 5,
//   railJunctionsSlider: 5,
//   safeHavensSlider: 5,
//   tunnelsSlider: 5,
//   weatherSlider: 5,
//   wildfiresSlider: 5,
// };

const initialState = {
    bridge_overpass: 2.2,
    bridge_underpass: 2.5,
    bridge_overwater: 3,
    bridge_overland: 2.3,
    tunnel: 5.5,
    rail: 6
};

const sliderSlice = createSlice({
  name: "sliders",
  initialState,
  reducers: {
    // setCellCoverageSlider: (state, action) => {
    //   state.cellCoverageSlider = action.payload;
    // },
    // setIncidentsSlider: (state, action) => {
    //   state.incidentsSlider = action.payload;
    // },
    // setPopulationSlider: (state, action) => {
    //   state.populationSlider = action.payload;
    // },
    // setRailJunctionsSlider: (state, action) => {
    //   state.railJunctionsSlider = action.payload;
    // },
    // setSafeHavensSlider: (state, action) => {
    //   state.safeHavensSlider = action.payload;
    // },
    // setTunnelsSlider: (state, action) => {
    //   state.tunnelsSlider = action.payload;
    // },
    // setWeatherSlider: (state, action) => {
    //   state.weatherSlider = action.payload;
    // },
    // setWildfiresSlider: (state, action) => {
    //   state.wildfiresSlider = action.payload;
    // },
    setBridgeOverpassSlider: (state, action) => {
      state.bridge_overpass = action.payload;
    },
    setBridgeUnderPassSlider: (state, action) => {
      state.bridge_underpass = action.payload;
    },
    setBridgeOverWaterSlider: (state, action) => {
      state.bridge_overwater = action.payload;
    },
    setBridgeOverLandSlider: (state, action) => {
      state.bridge_overland = action.payload;
    },
    setTunnelSlider: (state, action) => {
      state.tunnel = action.payload;
    },
    setRailSlider: (state, action) => {
      state.rail = action.payload;
    }
  },
});

// export const { setCellCoverageSlider } = sliderSlice.actions;
// export const { setIncidentsSlider } = sliderSlice.actions;
// export const { setPopulationSlider } = sliderSlice.actions;
// export const { setRailJunctionsSlider } = sliderSlice.actions;
// export const { setSafeHavensSlider } = sliderSlice.actions;
// export const { setTunnelsSlider } = sliderSlice.actions;
// export const { setWeatherSlider } = sliderSlice.actions;
// export const { setWildfiresSlider } = sliderSlice.actions;
export const { setBridgeOverpassSlider } = sliderSlice.actions;
export const { setBridgeUnderPassSlider } = sliderSlice.actions;
export const { setBridgeOverWaterSlider } = sliderSlice.actions;
export const { setBridgeOverLandSlider } = sliderSlice.actions;
export const { setTunnelSlider } = sliderSlice.actions;
export const { setRailSlider } = sliderSlice.actions;

export default sliderSlice.reducer;

// export const getCellCoverageSlider = (state: any) =>
//   state.sliders.cellCoverageSlider;
// export const getIncidentsSlider = (state: any) => state.sliders.incidentsSlider;
// export const getPopulationSlider = (state: any) =>
//   state.sliders.populationSlider;
// export const getRailJunctionsSlider = (state: any) =>
//   state.sliders.railJunctionsSlider;
// export const getSafeHavensSlider = (state: any) =>
//   state.sliders.safeHavensSlider;
// export const getTunnelsSlider = (state: any) => state.sliders.tunnelsSlider;
// export const getWeatherSlider = (state: any) => state.sliders.weatherSlider;
// export const getWildfiresSlider = (state: any) => state.sliders.wildfiresSlider;


export const getBridgeOverpassSlider = (state: any) => state.sliders.bridge_overpass;
export const getBridgeUnderPassSlider = (state: any) => state.sliders.bridge_underpass;
export const getBridgeOverWaterSlider = (state: any) => state.sliders.bridge_overwater;
export const getBridgeOverLandSlider = (state: any) => state.sliders.bridge_overland;
export const getTunnelSlider = (state: any) => state.sliders.tunnel;
export const getRailSlider = (state: any) => state.sliders.rail;

