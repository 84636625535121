import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: {
    token: "null",
  },
};

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action) => {
      try {
        state.token = action.payload;
      } catch (e) {
        alert("Failed to set jwt token");
      }
    },
  },
});

export const { setToken } = tokenSlice.actions;

export default tokenSlice.reducer;

export const getToken = (state: any) => state.token.token;
