import { createSlice } from "@reduxjs/toolkit";
import TestRoute from "../../components/testRoutes/test_route_id-1.json";

const initialState = {
  route: TestRoute,
  route2: TestRoute,
  route3: TestRoute,
  routeName: "",
  routeName2: "",
  routeName3: "",
  routeId: 999999,
};

const uploadedRouteSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setRoute: (state, action) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.route = action.payload;
        console.log(action.payload);
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
    setRoute2: (state, action) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.route2 = action.payload;
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
    setRoute3: (state, action) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.route3 = action.payload;
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
    setRouteName: (state, action) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.routeName = action.payload;
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
    setRouteName2: (state, action) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.routeName2 = action.payload;
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
    setRouteName3: (state, action) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.routeName3 = action.payload;
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
    setRouteId: (state, action) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.routeId = action.payload;
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
  },
});

export const { setRoute } = uploadedRouteSlice.actions;
export const { setRoute2 } = uploadedRouteSlice.actions;
export const { setRoute3 } = uploadedRouteSlice.actions;
export const { setRouteName } = uploadedRouteSlice.actions;
export const { setRouteName2 } = uploadedRouteSlice.actions;
export const { setRouteName3 } = uploadedRouteSlice.actions;
export const { setRouteId } = uploadedRouteSlice.actions;

export default uploadedRouteSlice.reducer;

export const getRoute = (state: any) => state.route.route;
export const getRoute2 = (state: any) => state.route.route2;
export const getRoute3 = (state: any) => state.route.route3;
export const getRouteName = (state: any) => state.route.routeName;
export const getRouteName2 = (state: any) => state.route.routeName2;
export const getRouteName3 = (state: any) => state.route.routeName3;
export const getRouteId = (state: any) => state.route.routeId;
