import { authFetch } from "../auth";

export async function client(endpoint, { body, timeout = 600000, ...customConfig } = {}) {
  const headers = { "Content-Type": "application/json" };
  const controller = new AbortController();
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    signal: controller.signal.timeout(timeout),
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const timeoutId = setTimeout(() => controller.abort(), timeout);

  let data;
  try {
    const response = await authFetch(endpoint, config);
    clearTimeout(timeoutId);
    data = await response.json();
    if (response.ok) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    if (err.name === 'AbortError') {
      return Promise.reject('Request timed out');
    }
    return Promise.reject(err.message ? err.message : data);
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: "GET" });
};

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body });
};
