import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chart: 1,
};

const chartDisplaySlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    setChart: (state, action) => {
      console.log(state);
      console.log(action.payload);
      state.chart = action.payload;
    },
  },
});

export const { setChart } = chartDisplaySlice.actions;

export default chartDisplaySlice.reducer;

export const getChart = (state: any) => state.chart.chart;
