import { useEffect } from "react";
import { StackedBarChartTemplate } from "./ChartTemplates";
import { useAppSelector } from "../../redux/hooks";
import Button from "@material-ui/core/Button";
import { getRoute, getRoute2, getRoute3 } from "../../redux/reducers/uploadedRouteSlice";
import { getChart } from "../../redux/reducers/chartDisplaySlice";

const colors = [
  "#702963",
  "#1f78b4",
  "#33a02c",
  "#fb9a99",
  "#ff7f00",
  "#b15928",
];

const risk_factors = [
  "risk",
  "bridge_overwater",
  "bridge_overland",
  "bridge_overpass",
  "rail",
  "tunnel",
];

const risk_factors_match: any = {
  risk: "Risk Category",
  bridge_overwater: "Bridge Over Water",
  bridge_overland: "Bridge Over Land",
  bridge_overpass: "Bridge Overpass",
  rail: "Rail Crossing",
  tunnel: "Tunnels",
};

const risk_factors_legend = [
  "Risk Category",
  "Bridge Over Water",
  "Bridge Over Land",
  "Bridge Overpass",
  "Rail Crossing",
  "Tunnels",
];

interface RISKS {
  risk: string;
  bridge_overwater: string;
  bridge_overland: string;
  bridge_overpass: string;
  rail: string;
  tunnel: string;
}

function RiskStackedBarChart() {
  const loadedRoute = useAppSelector(getRoute);
  const loadedRoute2 = useAppSelector(getRoute2);
  const loadedRoute3 = useAppSelector(getRoute3);
  const chart = useAppSelector(getChart);

  let route;
  switch (chart) {
    case 0:
    case 1:
      route = loadedRoute;
      break;
    case 2:
      route = loadedRoute2;
      break;
    case 3:
      route = loadedRoute3;
      break;
    default:
      route = loadedRoute; // Fallback
  }

  const features: any = [];
  const addedNames = new Set();

  const risk_data = route.features
    .map((d: any, i: number) => {
      const risks: any = {};
      features.push(d);

      function gen(obj: RISKS) {
        for (const [key, value] of Object.entries(obj)) {
          if (key === "explanation") {
            for (const [subKey, subValue] of Object.entries(value)) {
              if (risk_factors.includes(subKey)) {
                risks[risk_factors_match[subKey]] = (subValue as number[])[0];
              }
            }
          } else {
            if (risk_factors.includes(key)) {
              risks[risk_factors_match[key]] = +value;
            }
          }
        }
      }

      gen(d.properties);
      risks["name"] = i.toString();

      if (addedNames.has(risks["name"])) {
        return null; // Skip duplicate names
      }

      addedNames.add(risks["name"]);
      return risks;
    })
    .filter((risks: any) => risks !== null);

    const divStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '100%',
      overflow: 'hidden',
    };
    
    const chartStyle: React.CSSProperties = {
      height: '350px',
      width: '100%',
      overflow: 'hidden',
    };
    
    return (
      <div style={{ ...divStyle, height: '100%', overflow: 'auto' }}>
      <div style={chartStyle}>
        <StackedBarChartTemplate
          data={risk_data}
          xAxis="name"
          yAxis=""
          risks={risk_factors_legend}
          colors={colors}
          features={features}
          lineKey="Risk Category"
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        {/* Any additional content can go here */}
      </div>
    </div>
    );
}

export { RiskStackedBarChart };
