import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../utils/client.js";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchPopulation = createAsyncThunk(
  "population/fetchPopulation",
  async () => {
    const response = await client.get("./population");
    return response;
  },
);

const populationSlice = createSlice({
  name: "population",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPopulation.pending.type]: (state, action) => {
      state.status = "loading";
    },
    [fetchPopulation.fulfilled.type]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchPopulation.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export default populationSlice.reducer;

export const getPopulation = (state: any) => state.population.data;
