import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import policeReducer from "./reducers/policeSlice";
import routeRiskReducer from "./reducers/routeRiskSlice";
import populationReducer from "./reducers/populationSlice";
import highlightedRouteReducer from "./reducers/highlightedRouteSlice";
import uploadedRouteReducer from "./reducers/uploadedRouteSlice";
import vulnScoresReducer from "./reducers/vulnScoresSlice";
import userRoutesReducer from "./reducers/userRoutesSlice";
import backdropSliceReducer from "./reducers/backdropSlice";
import chartDisplayReducer from "./reducers/chartDisplaySlice";
import selectedRoutesReducer from "./reducers/selectedRoutesSlice";
import sliderReducer from "./reducers/sliderSlice";
import tokenReducer from "./reducers/tokenSlice";
import destinationReducer from "./reducers/destinationsSlice";
import tstarReducer from "./reducers/tstarSlice";

export const store = configureStore({
  reducer: {
    police: policeReducer,
    risk: routeRiskReducer,
    population: populationReducer,
    highlightedRoute: highlightedRouteReducer,
    route: uploadedRouteReducer,
    vulnScores: vulnScoresReducer,
    userRoutes: userRoutesReducer,
    backdrop: backdropSliceReducer,
    chart: chartDisplayReducer,
    routes: selectedRoutesReducer,
    sliders: sliderReducer,
    token: tokenReducer,
    destinations: destinationReducer,
    tstar: tstarReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
