import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaigns: [
    {
      id: 1,
      name: "SecuTest",
      last_activity: "",
      unit_id: 1,
      unit_name: "",
      total_alerts: 6375,
      unacknowledged_alerts: 6374,
      unit_message_count: 55343,
      enabled: true,
      armed: null,
      archived: false,
      deleted: false,
      vehicle: null,
      cargo: null,
      watchers: null,
      users: null,
      fences: null,
    },
  ],
  units: [
    {
      id: 1,
      unit_serial: "GEN_796590644236",
      name: "SecuBox",
      iridium_imei: "000000000000000",
      provisioned: true,
      timestamp: "2024-07-24T17:14:27.751315+00:00",
      campaign_id: 1,
      campaign_name: "SecuTest",
    },
  ],
  selectedShipment: 0,
  shipmentStatus: {
    unitState: "Armed",
    lastContact: "10 minutes ago",
  },
  events: [
    {
      id: "",
      event_type: "",
      alarm: true,
      campaign_id: 1,
      unit_id: 0,
      generated_timestamp: "",
      received_timestamp: "2024-07-24T14:48:29.151+00:00",
      latitude: 0,
      longitude: 0,
      msg_data: {
        source_id: 0,
        unit_name: "SecuBox",
        sensor_name: "0",
      },
      notification_sent: false,
    },
    // Add more events if needed
  ],
  positions: [] as Array<{
    id: string;
    unit_id: number;
    latitude: number;
    longitude: number;
    position: string;
    course: number;
    speed: number;
    generated_timestamp: string;
    received_timestamp: string;
    campaign_id: number;
    channel: string;
  }>,
  lineStringCoordinates: [],
};

export const tstarSlice = createSlice({
  name: "tstar",
  initialState,
  reducers: {
    setSelectedShipment: (state, action) => {
      state.selectedShipment = action.payload;
    },
    setShipmentData: (state, action) => {
      const { shipmentStatus, events } = action.payload;
      state.shipmentStatus = shipmentStatus;
      state.events = events;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setUnits: (state, action) => {
      state.units = action.payload;
    },
    setShipmentStatus: (state, action) => {
      state.shipmentStatus = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setPositions: (state, action) => {
      state.positions = action.payload;
    },
    setLineStringCoordinates: (state, action) => {
      state.lineStringCoordinates = action.payload;
    },
  },
});

export const {
  setSelectedShipment,
  setShipmentData,
  setCampaigns,
  setUnits,
  setShipmentStatus,
  setEvents,
  setPositions,
  setLineStringCoordinates,
} = tstarSlice.actions;

export const selectSelectedShipment = (state: any) =>
  state.tstar.selectedShipment;
export const selectShipmentStatus = (state: any) => state.tstar.shipmentStatus;
export const selectEvents = (state: any) => state.tstar.events;
export const selectCampaigns = (state: any) => state.tstar.campaigns;
export const selectUnits = (state: any) => state.tstar.units;
export const selectPositions = (state: any) => state.tstar.positions;
export const selectLineStringCoordinates = (state: any) =>
  state.tstar.lineStringCoordinates;

export default tstarSlice.reducer;
