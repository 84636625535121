import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  route1Checked: true,
  route2Checked: false,
  route3Checked: false,
  visibilityState: true,
  visibilityState2: true,
  visibilityState3: true,
};

const selectedRoutesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setRoute1: (state, action) => {
      state.route1Checked = action.payload;
      console.log(state.route1Checked);
    },
    setRoute2: (state, action) => {
      state.route2Checked = action.payload;
      console.log(state.route2Checked);
    },
    setRoute3: (state, action) => {
      state.route3Checked = action.payload;
      console.log(state.route3Checked);
    },
    setVisibility: (state, action) => {
      state.visibilityState = action.payload;
      console.log(state.visibilityState);
    },
    setVisibility2: (state, action) => {
      state.visibilityState2 = action.payload;
      console.log(state.visibilityState2);
    },
    setVisibility3: (state, action) => {
      state.visibilityState3 = action.payload;
      console.log(state.visibilityState3);
    },
  },
});

export const { setRoute1 } = selectedRoutesSlice.actions;
export const { setRoute2 } = selectedRoutesSlice.actions;
export const { setRoute3 } = selectedRoutesSlice.actions;
export const { setVisibility } = selectedRoutesSlice.actions;
export const { setVisibility2 } = selectedRoutesSlice.actions;
export const { setVisibility3 } = selectedRoutesSlice.actions;

export default selectedRoutesSlice.reducer;

export const getRoute1 = (state: any) => state.routes.route1Checked;
export const getRoute2 = (state: any) => state.routes.route2Checked;
export const getRoute3 = (state: any) => state.routes.route3Checked;
export const getVisibility = (state: any) => state.routes.visibilityState;
export const getVisibility2 = (state: any) => state.routes.visibilityState2;
export const getVisibility3 = (state: any) => state.routes.visibilityState3;
