import { setRoute } from "../../redux/reducers/uploadedRouteSlice";
import { setRoute2 } from "../../redux/reducers/uploadedRouteSlice";
import { setRoute3 } from "../../redux/reducers/uploadedRouteSlice";
import { setVulnScores } from "../../redux/reducers/vulnScoresSlice";
import { setVulnScores2 } from "../../redux/reducers/vulnScoresSlice";
import { setVulnScores3 } from "../../redux/reducers/vulnScoresSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import Button from "@material-ui/core/Button";
import { authFetch } from "../../auth";
import {
  getRoute,
  getRouteId,
  setRouteId,
} from "../../redux/reducers/uploadedRouteSlice";
import { getRoute2 } from "../../redux/reducers/uploadedRouteSlice";
import { getRoute3 } from "../../redux/reducers/uploadedRouteSlice";
import { setBackdrop } from "../../redux/reducers/backdropSlice";
import {
  setRouteName,
  setRouteName2,
  setRouteName3,
} from "../../redux/reducers/uploadedRouteSlice";
import {
  setRoute1 as setRoute1Checked,
  setRoute2 as setRoute2Checked,
  setRoute3 as setRoute3Checked,
} from "../../redux/reducers/selectedRoutesSlice";

import React, { useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import TestRoute from "../../components/testRoutes/test_route_id-1.json";
import {
  setBridgeOverpassSlider,
  setBridgeUnderPassSlider,
  setBridgeOverWaterSlider,
  setBridgeOverLandSlider,
  setTunnelSlider,
  setRailSlider,
  getBridgeOverpassSlider,
  getBridgeUnderPassSlider,
  getBridgeOverWaterSlider,
  getBridgeOverLandSlider,
  getTunnelSlider,
  getRailSlider,
} from "../../redux/reducers/sliderSlice";

import {
  setVisibility2,
  setVisibility3,
} from "../../redux/reducers/selectedRoutesSlice";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RouteUpload() {
  let routeId = useAppSelector(getRouteId);
  let loadedRoute = useAppSelector(getRoute).features[0].properties.route_id;
  let loadedRoute2 = useAppSelector(getRoute2).features[0].properties.route_id;
  let loadedRoute3 = useAppSelector(getRoute3).features[0].properties.route_id;
  let route1 = useAppSelector(getRoute);
  let route2 = useAppSelector(getRoute2);
  let route3 = useAppSelector(getRoute3);

  const bridgeOverpassSliderValue = useAppSelector(getBridgeOverpassSlider);
  const bridgeUnderpassSliderValue = useAppSelector(getBridgeUnderPassSlider);
  const bridgeOverWaterSliderValue = useAppSelector(getBridgeOverWaterSlider);
  const bridgeOverLandSliderValue = useAppSelector(getBridgeOverLandSlider);
  const tunnelSliderValue = useAppSelector(getTunnelSlider);
  const railSliderValue = useAppSelector(getRailSlider);

  let singleRoute = false;

  const dispatch = useAppDispatch();
  const [uploadError, setUploadError] = useState(false);

  function toggleBackdropOn() {
    dispatch(setBackdrop({ status: "true" }));
  }
  function toggleBackdropOff() {
    dispatch(setBackdrop({ status: "false" }));
  }

  function geocode() {
    console.log("geocode");
    let origin = localStorage.getItem("origin");
    let destination = localStorage.getItem("destination");
    let waypointsString = localStorage.getItem("waypoints");
    let waypoints = waypointsString ? JSON.parse(waypointsString) : [];

    console.log(origin, destination, waypoints);

    if (origin && destination) {
      let originSplit, destinationSplit;

      if (origin) {
        origin = origin.substring(1, origin.length - 1);
        originSplit = origin.split(",");
        console.log(originSplit);
      }

      if (destination) {
        destination = destination.substring(1, destination.length - 1);
        destinationSplit = destination.split(",");
        console.log(destinationSplit);
      }

      // Function to limit decimal places
      const limitDecimals = (num: number, decimals: number) => {
        const rounded = parseFloat(num.toFixed(decimals));
        return rounded.toString();
      };

      const routePoints = [
        originSplit,
        ...waypoints.map((wp: { lng: number; lat: number }) => [
          limitDecimals(wp.lng, 5),
          limitDecimals(wp.lat, 5),
        ]),
        destinationSplit,
      ];

      console.log(routePoints);

      // generating route with lat/long
      generateRoute(...routePoints);

      // resetting controls (example reset, adjust as needed)
      dispatch(setBridgeOverpassSlider(2.2));
      dispatch(setBridgeUnderPassSlider(3));
      dispatch(setBridgeOverWaterSlider(3));
      dispatch(setBridgeOverLandSlider(2.3));
      dispatch(setTunnelSlider(5.5));
      dispatch(setRailSlider(6));
      dispatch(setVisibility2(true));
      dispatch(setVisibility3(true));
    } else {
      alert("Please select an origin and destination.");
    }
  }

  function generateRoute(...routePoints: any[]) {
    singleRoute = false;

    dispatch(setBackdrop({ status: "true" }));

    // Initialize the body object
    let body: { [key: string]: any } = {};

    // Process each route point dynamically
    routePoints.forEach((point, index) => {
      if (point) {
        const xKey = index === 0 ? "org_x" : `dest${index}_x`;
        const yKey = index === 0 ? "org_y" : `dest${index}_y`;

        body[xKey] = parseFloat((point[0] as any).replaceAll(" ", ""));
        body[yKey] = parseFloat((point[1] as any).replaceAll(" ", ""));
      }
    });

    console.log(body);

    let endpoint =
      Object.keys(body).length > 4
        ? "generate_waypoints_route"
        : "generate_osm_route";

    //testing mult-route endpoint
    authFetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      // let vulnDefaults = {
      //   delaysWeight: 0,
      //   cellProvider: "any",
      //   cellCoverageWeight: 0.04,
      //   incidentsWeight: 4,
      //   popWeight: 4,
      //   railJunctionsWeight: 4,
      //   outOfServiceAreaWeight: 4,
      //   tunnelsWeight: 4,
      //   weatherWeight: 0.04,
      //   wildfiresWeight: 4,
      // };
      if (!res.ok) {
        dispatch(setBackdrop({ status: "false" }));
        alert(
          "Failed to generate route(s). Please select a new start location and destination and try again."
        );
      }
      if (res.ok) {
        let returnedRouteId = res.json(); // Route id returned from db
        returnedRouteId.then(function (generated_route_id: any) {
          let route1 = generated_route_id;
          dispatch(setRouteId(route1));
          // let route2 = generated_route_id + 1;
          // let route3 = generated_route_id + 2;
          console.log(generated_route_id);
          // console.log(Number(generated_route_id) + 1);
          // console.log(Number(generated_route_id) + 2);

          //Need to run vuln scores for each
          //vuln score store needs to hold list of routes
          //then load route for each
          //load route needs to push to a store that can hold list of routes

          loadRoute(route1);

          // authFetch("/proxy/vuln_calc/" + route1, {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify(vulnDefaults),
          // }).then((res) => {
          //   console.log(res);
          //   let response = res.json();
          //   response.then(function (result: any) {
          //     console.log(result);
          //     dispatch(setVulnScores(result));
          //     loadRoute(route1);
          //   });

          //   //route 2
          //   // authFetch("/proxy/vuln_calc/" + route2, {
          //   //   method: "POST",
          //   //   headers: {
          //   //     "Content-Type": "application/json",
          //   //   },
          //   //   body: JSON.stringify(vulnDefaults),
          //   // }).then((res) => {
          //   //   let response = res.json();
          //   //   response.then(function (result: any) {
          //   //     console.log(result);
          //   //     // console.log(document.getElementById('vuln')!);
          //   //     dispatch(setVulnScores2(result));
          //   //     // loadRoute(setRoute2);
          //   //   });

          //   //   //route 3
          //   //   authFetch("/proxy/vuln_calc/" + route3, {
          //   //     method: "POST",
          //   //     headers: {
          //   //       "Content-Type": "application/json",
          //   //     },
          //   //     body: JSON.stringify(vulnDefaults),
          //   //   }).then((res) => {
          //   //     let response = res.json();
          //   //     response.then(function (result: any) {
          //   //       console.log(result);
          //   //       // console.log(document.getElementById('vuln')!);
          //   //       dispatch(setVulnScores3(result));
          //   //       // loadRoute(route3);
          //   //       loadRoute(route1);
          //   //     });
          //   //   });
          //   // });
          // });
        });
      }
    });
  }

  function runVulnerabilityCalc() {
    console.log(routeId);
    loadRoute(routeId);
    // document.getElementById('refreshRoute')!.click();
    // console.log(routeId);
    // if (routeId) {
    //   let body = {
    //     route_id: routeId,
    //     likert_scores: {
    //       bridge_overpass: bridgeOverpassSliderValue,
    //       bridge_underpass: bridgeUnderpassSliderValue,
    //       bridge_overwater: bridgeOverWaterSliderValue,
    //       tunnel: tunnelSliderValue,
    //       rail: railSliderValue,
    //     },
    //   };
    //   dispatch(setBackdrop({ status: "true" }));
    //   authFetch("generate_osm_route", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(body),
    //   }).then((res) => {
    //     if (!res.ok) {
    //       dispatch(setBackdrop({ status: "false" }));
    //       alert(
    //         "Failed to generate route(s). Please select a new start location and destination and try again."
    //       );
    //     }
    //     if (res.ok) {
    //       let returnedRouteId = res.json(); // Route id returned from db
    //       returnedRouteId.then(function (generated_route_id: any) {
    //         //put here
    //         let route1 = generated_route_id;
    //         dispatch(loadedRoute(route1));
    //         console.log(generated_route_id);
    //         loadRoute(route1);
    //       });
    //     }
    //   });

    // let delaysWeight = parseInt((document.getElementById('delays-slider')!.children[2] as HTMLInputElement).value, 10);
    // let delaysWeight = 0;
    // let cellProvider = (
    //   document.getElementById("cell-provider") as HTMLInputElement
    // ).value;
    // let cellCoverageWeight = parseInt(
    //   (
    //     document.getElementById("cellCoverageSlider")!
    //       .children[2] as HTMLInputElement
    //   ).value,
    //   10
    // );
    // let incidentsWeight = parseInt(
    //   (
    //     document.getElementById("incidentsSlider")!
    //       .children[2] as HTMLInputElement
    //   ).value,
    //   10
    // );
    // let popWeight = parseInt(
    //   (
    //     document.getElementById("populationSlider")!
    //       .children[2] as HTMLInputElement
    //   ).value,
    //   10
    // );
    // let railJunctionsWeight = parseInt(
    //   (
    //     document.getElementById("railJunctionsSlider")!
    //       .children[2] as HTMLInputElement
    //   ).value,
    //   10
    // );
    // let outOfServiceAreaWeight = parseInt(
    //   (
    //     document.getElementById("safeHavensSlider")!
    //       .children[2] as HTMLInputElement
    //   ).value,
    //   10
    // );
    // let tunnelsWeight = parseInt(
    //   (
    //     document.getElementById("tunnelsSlider")!
    //       .children[2] as HTMLInputElement
    //   ).value,
    //   10
    // );
    // let weatherWeight = parseInt(
    //   (
    //     document.getElementById("weatherSlider")!
    //       .children[2] as HTMLInputElement
    //   ).value,
    //   10
    // );
    // let wildfiresWeight = parseInt(
    //   (
    //     document.getElementById("wildfiresSlider")!
    //       .children[2] as HTMLInputElement
    //   ).value,
    //   10
    // );

    // delaysWeight = (delaysWeight * 10) - 10;
    // cellCoverageWeight = (cellCoverageWeight - 1) / 100;
    // incidentsWeight = incidentsWeight - 1;
    // popWeight = popWeight - 1;
    // railJunctionsWeight = railJunctionsWeight - 1;
    // outOfServiceAreaWeight = outOfServiceAreaWeight - 1;
    // tunnelsWeight = tunnelsWeight - 1;
    // weatherWeight = (weatherWeight - 1) / 100;
    // wildfiresWeight = wildfiresWeight - 1;

    // let body = {
    //   cellProvider: cellProvider,
    //   delaysWeight: delaysWeight,
    //   cellCoverageWeight: cellCoverageWeight,
    //   incidentsWeight: incidentsWeight,
    //   popWeight: popWeight,
    //   railJunctionsWeight: railJunctionsWeight,
    //   outOfServiceAreaWeight: outOfServiceAreaWeight,
    //   tunnelsWeight: tunnelsWeight,
    //   weatherWeight: weatherWeight,
    //   wildfiresWeight: wildfiresWeight,
    // };

    //hardcoding this for Jonathan's new function:
    // let body = {
    //   route_id: 999999,
    //   likert_scores: {
    //     bridge_overpass: bridgeOverpassSliderValue,
    //     bridge_underpass: bridgeUnderpassSliderValue,
    //     bridge_overwater: bridgeOverWaterSliderValue,
    //     tunnel: tunnelSliderValue,
    //     rail: railSliderValue,
    //   },
    // };

    // let body = {
    //       "delaysWeight": 90,
    //       "incidentsWeight": 90,
    //       "popWeight": 90,
    //       "railJunctionsWeight": 90,
    //       "safeHavensWeight": 90,
    //       "tunnelsWeight": 90,
    //       "weatherWeight": 90,
    //       "wildfiresWeight": 90
    // }

    //   console.log(body);
    //   console.log(loadedRoute);

    //   authFetch("/proxy/vuln_calc/" + loadedRoute, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(body),
    //   }).then((res) => {
    //     let response = res.json();
    //     response.then(function (result: any) {
    //       console.log(result);
    //       // console.log(document.getElementById('vuln')!);
    //       dispatch(setVulnScores(result));
    //       // loadRoute(loadedRoute);
    //       console.log(loadedRoute);
    //       if (!loadedRoute2) {
    //         singleRoute = true;
    //         loadRoute(loadedRoute);
    //         dispatch(
    //           setVulnScores2({
    //             vulnScore: 0,
    //             cellCoverageScore: 0,
    //             delayScore: 0,
    //             popScore: 0,
    //             weatherScore: 0,
    //             incidentScore: 0,
    //             wildfireScore: 0,
    //             railJunctionScore: 0,
    //             tunnelScore: 0,
    //             outOfServiceAreaScore: 0,
    //             cellProvider: "Any",
    //           })
    //         );
    //         dispatch(
    //           setVulnScores3({
    //             vulnScore: 0,
    //             cellCoverageScore: 0,
    //             delayScore: 0,
    //             popScore: 0,
    //             weatherScore: 0,
    //             incidentScore: 0,
    //             wildfireScore: 0,
    //             railJunctionScore: 0,
    //             tunnelScore: 0,
    //             outOfServiceAreaScore: 0,
    //             cellProvider: "Any",
    //           })
    //         );
    //       } else {
    //         let loadedRoute2 = loadedRoute + 1;
    //         let loadedRoute3 = loadedRoute + 2;
    //         //route 2
    //         authFetch("/proxy/vuln_calc/" + loadedRoute2, {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //           body: JSON.stringify(body),
    //         }).then((res) => {
    //           let response = res.json();
    //           response.then(function (result2: any) {
    //             console.log(result2);
    //             // console.log(document.getElementById('vuln')!);
    //             dispatch(setVulnScores2(result2));
    //             // loadRoute(loadedRoute);

    //             //route 3
    //             authFetch("/proxy/vuln_calc/" + loadedRoute3, {
    //               method: "POST",
    //               headers: {
    //                 "Content-Type": "application/json",
    //               },
    //               body: JSON.stringify(body),
    //             }).then((res) => {
    //               let response = res.json();
    //               response.then(function (result3: any) {
    //                 console.log(result3);
    //                 // console.log(document.getElementById('vuln')!);
    //                 dispatch(setVulnScores3(result3));
    //                 loadRoute(loadedRoute);
    //                 if (document.getElementById("reset_route_checkbox")) {
    //                   document.getElementById("reset_route_checkbox")!.click();
    //                 }
    //               });
    //             });
    //           });
    //         });
    //       }
    //     });
    //   });
    // }
    //only 1 route loaded when running vuln calc
    // else {
    // }
  }

  function loadRoute(routeId: any) {
    // dispatch(setRouteId(routeId));
    let routeName = "";
    //clear old routes out
    dispatch(setRoute(TestRoute));
    dispatch(setRoute2(TestRoute));
    dispatch(setRoute3(TestRoute));

    //how we handle if loading from dropdown list
    if (routeId == "0") {
      console.log(document.getElementById("loadRoute") as HTMLInputElement);
      routeId = (document.getElementById("loadRoute") as HTMLInputElement)
        .value;
      routeName = (document.getElementById("loadRoute") as HTMLInputElement)
        .name;
    }
    console.log(routeId);
    console.log(routeName);
    dispatch(setRouteId(routeId));

    //clearing vuln scores if we're loading one of the default routes --we'll have to handle this differently
    //for user generated/saved individual routes.
    //Need to double check this works with generate routes
    // if (routeId <= 5 || routeName !== "") {
    //   //disabling preloaded routes currently
    //   dispatch(setBackdrop({ status: "false" }));
    //   return;
    //   // dispatch(setRoute2(TestRoute));
    //   // dispatch(setRoute3(TestRoute));
    //   // dispatch(setRoute1Checked(true));
    //   // dispatch(setRoute2Checked(false));
    //   // dispatch(setRoute3Checked(false));
    //   // dispatch(setRouteName2(""));
    //   // dispatch(setRouteName3(""));
    //   // dispatch(
    //   //   setVulnScores2({
    //   //     vulnScore: 0,
    //   //     cellCoverageScore: 0,
    //   //     delayScore: 0,
    //   //     popScore: 0,
    //   //     weatherScore: 0,
    //   //     incidentScore: 0,
    //   //     wildfireScore: 0,
    //   //     railJunctionScore: 0,
    //   //     tunnelScore: 0,
    //   //     outOfServiceAreaScore: 0,
    //   //     cellProvider: "Any",
    //   //   })
    //   // );
    //   // dispatch(
    //   //   setVulnScores3({
    //   //     vulnScore: 0,
    //   //     cellCoverageScore: 0,
    //   //     delayScore: 0,
    //   //     popScore: 0,
    //   //     weatherScore: 0,
    //   //     incidentScore: 0,
    //   //     wildfireScore: 0,
    //   //     railJunctionScore: 0,
    //   //     tunnelScore: 0,
    //   //     outOfServiceAreaScore: 0,
    //   //     cellProvider: "Any",
    //   //   })
    //   // );
    // }

    // let cellProvider = "any";
    // let delaysWeight = 0;
    // let cellCoverageWeight = 20;
    // let incidentsWeight = 20;
    // let popWeight = 15;
    // let railJunctionsWeight = 10;
    // let safeHavensWeight = 15;
    // let tunnelsWeight = 20;
    // let weatherWeight = 15;
    // let wildfiresWeight = 60;
    // let delaysWeight = 0;
    // let cellCoverageWeight = 0.04;
    // let incidentsWeight = 4;
    // let popWeight = 4;
    // let railJunctionsWeight = 4;
    // let outOfServiceAreaWeight = 4;
    // let tunnelsWeight = 4;
    // let weatherWeight = 0.04;
    // let wildfiresWeight = 4;

    // if (document.getElementById("cell-provider")) {
    //   cellProvider = (
    //     document.getElementById("cell-provider") as HTMLInputElement
    //   ).value;
    // }
    // if (document.getElementById("cellCoverageSlider")) {
    //   cellCoverageWeight = parseInt(
    //     (
    //       document.getElementById("cellCoverageSlider")!
    //         .children[2] as HTMLInputElement
    //     ).value,
    //     10
    //   );
    //   cellCoverageWeight = (cellCoverageWeight - 1) / 100;
    // }
    // if (document.getElementById("incidentsSlider")) {
    //   incidentsWeight = parseInt(
    //     (
    //       document.getElementById("incidentsSlider")!
    //         .children[2] as HTMLInputElement
    //     ).value,
    //     10
    //   );
    //   incidentsWeight = incidentsWeight - 1;
    // }
    // if (document.getElementById("populationSlider")) {
    //   popWeight = parseInt(
    //     (
    //       document.getElementById("populationSlider")!
    //         .children[2] as HTMLInputElement
    //     ).value,
    //     10
    //   );
    //   popWeight = popWeight - 1;
    // }
    // if (document.getElementById("railJunctionsSlider")) {
    //   railJunctionsWeight = parseInt(
    //     (
    //       document.getElementById("railJunctionsSlider")!
    //         .children[2] as HTMLInputElement
    //     ).value,
    //     10
    //   );
    //   railJunctionsWeight = railJunctionsWeight - 1;
    // }
    // if (document.getElementById("safeHavensSlider")) {
    //   outOfServiceAreaWeight = parseInt(
    //     (
    //       document.getElementById("safeHavensSlider")!
    //         .children[2] as HTMLInputElement
    //     ).value,
    //     10
    //   );
    //   outOfServiceAreaWeight = outOfServiceAreaWeight - 1;
    // }
    // if (document.getElementById("tunnelsSlider")) {
    //   tunnelsWeight = parseInt(
    //     (
    //       document.getElementById("tunnelsSlider")!
    //         .children[2] as HTMLInputElement
    //     ).value,
    //     10
    //   );
    //   tunnelsWeight = tunnelsWeight - 1;
    // }
    // if (document.getElementById("weatherSlider")) {
    //   weatherWeight = parseInt(
    //     (
    //       document.getElementById("weatherSlider")!
    //         .children[2] as HTMLInputElement
    //     ).value,
    //     10
    //   );
    //   // weatherWeight = ((weatherWeight * 10) - 10) * .1;
    //   weatherWeight = (weatherWeight - 1) / 100;
    //   console.log(weatherWeight);
    // }
    // if (document.getElementById("wildfiresSlider")) {
    //   wildfiresWeight = parseInt(
    //     (
    //       document.getElementById("wildfiresSlider")!
    //         .children[2] as HTMLInputElement
    //     ).value,
    //     10
    //   );
    //   wildfiresWeight = wildfiresWeight - 1;
    // }

    let with_scores = true;

    // let body = {
    //   route_id: routeId,
    //   with_scores: true,
    //   cellProvider: cellProvider,
    //   delaysWeight: delaysWeight,
    //   cellCoverageWeight: cellCoverageWeight,
    //   incidentsWeight: incidentsWeight,
    //   popWeight: popWeight,
    //   railJunctionsWeight: railJunctionsWeight,
    //   outOfServiceAreaWeight: outOfServiceAreaWeight,
    //   tunnelsWeight: tunnelsWeight,
    //   weatherWeight: weatherWeight,
    //   wildfiresWeight: wildfiresWeight,
    // };

    //hardcoding this for Jonathan's new function:
    let body = {
      route_id: routeId,
      likert_scores: {
        bridge_overpass: bridgeOverpassSliderValue,
        // bridge_underpass: bridgeUnderpassSliderValue,
        bridge_overwater: bridgeOverWaterSliderValue,
        bridge_overland: bridgeOverLandSliderValue,
        tunnel: tunnelSliderValue,
        rail: railSliderValue,
      },
    };

    // let body = {'route_id': routeId, 'with_scores': true}

    dispatch(setBackdrop({ status: "true" }));

    if (document.getElementById("reset_route_checkbox")) {
      document.getElementById("reset_route_checkbox")!.click();
    }

    //route 1
    authFetch("fetch_route", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (!res.ok) {
        dispatch(setBackdrop({ status: "false" }));
        alert(
          "Failed to generate route(s). Please select a new start location and destination and try again."
        );
        // throw new Error(res.statusText);
      }

      let returnedRoute = res.json(); // Route returned from db
      returnedRoute.then(function (result: any) {
        console.log(result.features);
        //temporary check in case this is null

        var features = result.features;

        for (var i = 0; i < features.length; i++) {
          let vul_score = features[i].properties.risk;
          if (vul_score < 1) {
            features[i].properties["color"] = "#2dc84d";
          }
          if (vul_score >= 1 && vul_score < 2) {
            features[i].properties["color"] = "#0c7c59";
          }
          if (vul_score >= 2 && vul_score < 3) {
            features[i].properties["color"] = "#ffb703";
          }
          if (vul_score >= 3 && vul_score < 4) {
            features[i].properties["color"] = "#feae6c";
          }
          if (vul_score >= 4 && vul_score < 5) {
            features[i].properties["color"] = "#ef6a00";
          }
          if (vul_score >= 5 && vul_score < 6) {
            features[i].properties["color"] = "#f93414";
          }
          if (vul_score == 6) {
            features[i].properties["color"] = "#61007d";
          }

          let normalized_vul_score =
            features[i].properties.vulnerability_score_normalized;
          if (normalized_vul_score < 1) {
            features[i].properties["normalized_color"] = "#00b8ff";
          }
          if (normalized_vul_score >= 1 && normalized_vul_score <= 1.99) {
            features[i].properties["normalized_color"] = "#3445c3";
          }
          if (normalized_vul_score >= 2 && normalized_vul_score <= 2.99) {
            features[i].properties["normalized_color"] = "#26477e";
          }
          if (normalized_vul_score > 2.99) {
            features[i].properties["normalized_color"] = "#1e225c";
          }
        }
        dispatch(setRoute(result));
        // dispatch(setRouteName(result.features[0].properties.nickname));
        dispatch(setRouteName(result.nickname || "Generated Route"));
        dispatch(setBackdrop({ status: "false" }));

        //TODO: This is a temporary fix for pre-loaded routes to only load the single route at a time

        //Need to test if this works properly with generated routes
        if (routeId > 5 && routeName == "" && singleRoute == false) {
          //route 2
          // body = {
          //   route_id: Number(routeId) + 1,
          //   with_scores: true,
          //   cellProvider: cellProvider,
          //   delaysWeight: delaysWeight,
          //   cellCoverageWeight: cellCoverageWeight,
          //   incidentsWeight: incidentsWeight,
          //   popWeight: popWeight,
          //   railJunctionsWeight: railJunctionsWeight,
          //   outOfServiceAreaWeight: outOfServiceAreaWeight,
          //   tunnelsWeight: tunnelsWeight,
          //   weatherWeight: weatherWeight,
          //   wildfiresWeight: wildfiresWeight,
          // };
          //hardcoding this for Jonathan's new function:
          // body = {
          //   route_id: 30,
          //   likert_scores: {
          //     bridge_overpass: "2",
          //     bridge_underpass: "2",
          //     bridge_overwater: "3",
          //     tunnel: "4",
          //     rail: "3",
          //   },
          // };
          // authFetch("fetch_route", {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify(body),
          // }).then((res) => {
          //   if (!res.ok) {
          //     dispatch(setBackdrop({ status: "false" }));
          //     throw new Error(res.statusText);
          //   }
          //   let returnedRoute = res.json(); // Route returned from db
          //   returnedRoute.then(function (result: any) {
          //     console.log(result);
          //     //temporary check in case this is null
          //     var features = result.features;
          //     for (var i = 0; i < features.length; i++) {
          //       let vul_score = features[i].properties.vulnerability_score;
          //       if (vul_score < 8.0) {
          //         features[i].properties["color"] = "#37b54b";
          //       }
          //       if (vul_score >= 8.0 && vul_score <= 15.99) {
          //         features[i].properties["color"] = "#ffbf00";
          //       }
          //       if (vul_score >= 16.0 && vul_score <= 24.0) {
          //         features[i].properties["color"] = "#ec5800";
          //       }
          //       if (vul_score > 24.0) {
          //         features[i].properties["color"] = "#e62020";
          //       }
          //       let normalized_vul_score =
          //         features[i].properties.vulnerability_score_normalized;
          //       if (normalized_vul_score < 1) {
          //         features[i].properties["normalized_color"] = "#00b8ff";
          //       }
          //       if (normalized_vul_score >= 1 && normalized_vul_score <= 1.99) {
          //         features[i].properties["normalized_color"] = "#3445c3";
          //       }
          //       if (normalized_vul_score >= 2 && normalized_vul_score <= 2.99) {
          //         features[i].properties["normalized_color"] = "#26477e";
          //       }
          //       if (normalized_vul_score > 2.99) {
          //         features[i].properties["normalized_color"] = "#1e225c";
          //       }
          //     }
          //     dispatch(setRoute2(result));
          //     // dispatch(setRouteName2(result.features[0].properties.nickname));
          //     dispatch(setRouteName2("Test 2"));
          //     dispatch(setBackdrop({ status: "false" }));
          //     //route 3
          //     // body = {
          //     //   route_id: Number(routeId) + 2,
          //     //   with_scores: true,
          //     //   cellProvider: cellProvider,
          //     //   delaysWeight: delaysWeight,
          //     //   cellCoverageWeight: cellCoverageWeight,
          //     //   incidentsWeight: incidentsWeight,
          //     //   popWeight: popWeight,
          //     //   railJunctionsWeight: railJunctionsWeight,
          //     //   outOfServiceAreaWeight: outOfServiceAreaWeight,
          //     //   tunnelsWeight: tunnelsWeight,
          //     //   weatherWeight: weatherWeight,
          //     //   wildfiresWeight: wildfiresWeight,
          //     // };
          //     //hardcoding this for Jonathan's new function:
          //     body = {
          //       route_id: 30,
          //       likert_scores: {
          //         bridge_overpass: "2",
          //         bridge_underpass: "2",
          //         bridge_overwater: "3",
          //         tunnel: "4",
          //         rail: "3",
          //       },
          //     };
          //     authFetch("fetch_route", {
          //       method: "POST",
          //       headers: {
          //         "Content-Type": "application/json",
          //       },
          //       body: JSON.stringify(body),
          //     }).then((res) => {
          //       if (!res.ok) {
          //         dispatch(setBackdrop({ status: "false" }));
          //         throw new Error(res.statusText);
          //       }
          //       let returnedRoute = res.json(); // Route returned from db
          //       returnedRoute.then(function (result: any) {
          //         console.log(result);
          //         //temporary check in case this is null
          //         var features = result.features;
          //         for (var i = 0; i < features.length; i++) {
          //           let vul_score = features[i].properties.vulnerability_score;
          //           if (vul_score < 8.0) {
          //             features[i].properties["color"] = "#37b54b";
          //           }
          //           if (vul_score >= 8.0 && vul_score <= 15.99) {
          //             features[i].properties["color"] = "#ffbf00";
          //           }
          //           if (vul_score >= 16.0 && vul_score <= 24.0) {
          //             features[i].properties["color"] = "#ec5800";
          //           }
          //           if (vul_score > 24.0) {
          //             features[i].properties["color"] = "#e62020";
          //           }
          //           let normalized_vul_score =
          //             features[i].properties.vulnerability_score_normalized;
          //           if (normalized_vul_score < 1) {
          //             features[i].properties["normalized_color"] = "#00b8ff";
          //           }
          //           if (
          //             normalized_vul_score >= 1 &&
          //             normalized_vul_score <= 1.99
          //           ) {
          //             features[i].properties["normalized_color"] = "#3445c3";
          //           }
          //           if (
          //             normalized_vul_score >= 2 &&
          //             normalized_vul_score <= 2.99
          //           ) {
          //             features[i].properties["normalized_color"] = "#26477e";
          //           }
          //           if (normalized_vul_score > 2.99) {
          //             features[i].properties["normalized_color"] = "#1e225c";
          //           }
          //         }
          //         dispatch(setRoute3(result));
          //         dispatch(
          //           // setRouteName3(result.features[0].properties.nickname)
          //           setRouteName3("Test 3")
          //         );
          //         dispatch(setBackdrop({ status: "false" }));
          //       });
          //     });
          //   });
          // });
        } //end if
      });
    });
  }

  return (
    <React.Fragment>
      {/*<div>
            <Button color="inherit" onClick={() => upload()}>Upload Route</Button>
            <input type="file" hidden name="routeUploadBtn" id="routeUploadBtn" onChange={(e)=>handleUpload(e)}/>
        </div>*/}
      {/*<Snackbar open={uploadError} onClose={() => setUploadError(false)}>
			<Alert onClose={() => setUploadError(false)} severity="error">
				Failed to upload route to backend service.
			</Alert>
		</Snackbar>*/}
      <Button
        style={{ visibility: "hidden" }}
        id="loadRoute"
        value="0"
        color="inherit"
        onClick={() => loadRoute("0")}
      ></Button>
      {/*<Button style={{visibility: 'hidden'}} id='route1' color="inherit" onClick={() => loadRoute('1')}></Button>
        <Button style={{visibility: 'hidden'}} id='route2' color="inherit" onClick={() => loadRoute('2')}></Button>
        <Button style={{visibility: 'hidden'}} id='route3' color="inherit" onClick={() => loadRoute('3')}></Button>
        <Button style={{visibility: 'hidden'}} id='route4' color="inherit" onClick={() => loadRoute('4')}></Button>
        <Button style={{visibility: 'hidden'}} id='route5' color="inherit" onClick={() => loadRoute('5')}></Button>*/}
      <Button
        style={{ visibility: "hidden" }}
        id="toggleView"
        color="inherit"
        onClick={() => loadRoute("1")}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="vulnCalc"
        color="inherit"
        onClick={() => runVulnerabilityCalc()}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="generateRoute"
        color="inherit"
        onClick={() => geocode()}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="toggleBackdropOn"
        color="inherit"
        onClick={() => toggleBackdropOn()}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="toggleBackdropOff"
        color="inherit"
        onClick={() => toggleBackdropOff()}
      ></Button>
    </React.Fragment>
  );
}

export default RouteUpload;
