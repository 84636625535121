import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  linkIds: [],
};

const highlightedRouteSlice = createSlice({
  name: "highlightedRoute",
  initialState,
  reducers: {
    setLinkIds: (state, action) => {
      state.linkIds = action.payload;
    },
  },
});

export const { setLinkIds } = highlightedRouteSlice.actions;

export default highlightedRouteSlice.reducer;

export const getLinkIds = (state: any) => state.highlightedRoute.linkIds;
