import React, { useEffect } from "react";
import "./App.css";

import Dashboard from "./Dashboard";
import Login from "./Login";

import { useAuth } from "./auth";

export default function App() {
  const [authed] = useAuth();

  useEffect(() => {
    // Clear localStorage items here
    localStorage.removeItem("origin");
    localStorage.removeItem("destination");
    localStorage.removeItem("waypoints");

    // Any other initialization logic
  }, []); // Empty dependency array ensures this runs only once on mount

  return authed ? (
    <div className="App">
      <Dashboard />
    </div>
  ) : (
    <div className="App">
      <Login />
    </div>
  );
}
