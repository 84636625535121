import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { setLinkIds } from "../../redux/reducers/highlightedRouteSlice";
import mapboxgl from "mapbox-gl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  AreaChart,
  Area,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";

interface ChartPropsInterface {
  data: any;
  xAxis: string;
  yAxis: string;
  lineKey: string;
}

interface ChartPropsInterface2 {
  data: any;
  xAxis: string;
  yAxis: string;
  risks: any;
  colors: any;
  features: any;
  lineKey: string;
}

const chartWidth: number = 1300;
const chartHeight: number = 300; // Fixed height for the chart
const paginationHeight: number = 40; // Height for the pagination buttons
const chartMargins: any = {
  top: 5,
  right: 20,
  left: 20,
  bottom: 5,
};

function AreaChartTemplate(props: ChartPropsInterface) {
  return (
    <AreaChart
      width={chartWidth}
      height={chartHeight}
      data={props.data}
      margin={chartMargins}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.xAxis} />
      <YAxis />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="population"
        stackId="1"
        stroke="#8884d8"
        fill="#8884d8"
      />
      <Area
        type="monotone"
        dataKey="police"
        stackId="1"
        stroke="#82ca9d"
        fill="#82ca9d"
      />
    </AreaChart>
  );
}

function MultiLineChartTemplate(props: ChartPropsInterface) {
  return (
    <LineChart
      width={chartWidth}
      height={chartHeight}
      data={props.data}
      margin={chartMargins}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.xAxis} />
      <YAxis dataKey={props.yAxis} />
      <Tooltip />
      <Line type="monotone" dataKey="population" stroke="#8884d8" />
      <Line type="monotone" dataKey="police" stroke="#82ca9d" />
    </LineChart>
  );
}

function LineChartTemplate(props: ChartPropsInterface) {
  return (
    <LineChart
      width={chartWidth}
      height={chartHeight}
      data={props.data}
      margin={chartMargins}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.xAxis} />
      <YAxis dataKey={props.yAxis} />
      <Tooltip />
      <Line type="monotone" dataKey={props.lineKey} stroke="#82ca9d" />
    </LineChart>
  );
}

function StackedBarChartTemplate(props: ChartPropsInterface2) {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 100; // Pagination by 100 entries per page

  const needsPagination = props.data.length > pageSize;
  const paginatedData = needsPagination
    ? props.data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
    : props.data;

  function nextPage() {
    if ((currentPage + 1) * pageSize < props.data.length) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevPage() {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  function renderBars() {
    const colors = props.colors;

    return props.risks
      .filter((risk: string) => risk !== "Risk Category")
      .map((risk: string, index: number) => (
        <Bar
          key={risk} // Ensure unique key for each bar
          yAxisId="left"
          dataKey={risk}
          stackId="a"
          fill={colors[index]}
          onClick={onBarClick}
        />
      ));
  }

  function onBarClick(e: any) {
    const coords: any[] = [];
    const index = e.activeLabel || e.index || e.name;

    if (index !== undefined) {
      const coordinates = props.features[+index].geometry.coordinates;
      coordinates.forEach((coordinate: any) => coords.push(coordinate));
    }

    const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);
    coords.forEach((coord) => bounds.extend(coord));

    const bbox = `${bounds.getWest()}, ${bounds.getSouth()}, ${bounds.getEast()}, ${bounds.getNorth()}`;
    document.getElementById("BOUND")!.setAttribute("data-bound", bbox);
    document.getElementById("LINK_SELECTED")!.click();
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          height: paginationHeight,
        }}
      >
        <button
          onClick={prevPage}
          disabled={currentPage === 0}
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            padding: "5px",
            cursor: "pointer",
          }} // Consistent margins and padding
        >
          <ArrowBackIcon style={{ fontSize: "14px" }} />
        </button>
        <button
          onClick={nextPage}
          disabled={(currentPage + 1) * pageSize >= props.data.length}
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            padding: "5px",
            cursor: "pointer",
          }} // Consistent margins and padding
        >
          <ArrowForwardIcon style={{ fontSize: "14px" }} />
        </button>
      </div>

      <ResponsiveContainer width="100%" height={chartHeight}>
        <ComposedChart data={paginatedData} margin={chartMargins}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={props.xAxis} />
          <YAxis yAxisId="left" />
          <Tooltip />
          <Legend />
          {renderBars()}
          <Line
            yAxisId="left"
            type="monotone"
            dataKey={props.lineKey}
            stroke="#000000"
            dot={{ r: 3 }}
            activeDot={{ onClick: (event, payload) => onBarClick(payload) }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

function BarChartTemplate(props: ChartPropsInterface) {
  const dispatch = useAppDispatch();
  const [activeIds] = useState<Set<number>>(new Set());
  const [mouseIsClicked, setMouseIsClicked] = useState(false);

  function mouseDown() {
    setMouseIsClicked(true);
  }

  function mouseUp() {
    setMouseIsClicked(false);
    dispatch(setLinkIds(Array.from(activeIds)));
    activeIds.clear();
  }

  function mouseMove(e: any) {
    if (mouseIsClicked && e.activePayload) {
      activeIds.add(e.activePayload[0].payload.linkid);
    }
  }

  return (
    <BarChart
      width={chartWidth}
      height={chartHeight}
      data={props.data}
      margin={chartMargins}
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onMouseMove={mouseMove}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.xAxis} />
      <YAxis dataKey={props.yAxis} />
      <Tooltip />
      <Bar dataKey={props.lineKey}>
        {props.data.map((entry: any, index: number) => {
          let color = entry.selected ? "#ff3838" : "#82ca9d";
          return <Cell key={`cell-${index}`} fill={color} stroke={color} />;
        })}
      </Bar>
    </BarChart>
  );
}

export {
  LineChartTemplate,
  BarChartTemplate,
  MultiLineChartTemplate,
  AreaChartTemplate,
  StackedBarChartTemplate,
};
export type { ChartPropsInterface };
