import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vulnScores: {
    vulnScore: 0,
    cellCoverageScore: 0,
    delayScore: 0,
    popScore: 0,
    weatherScore: 0,
    incidentScore: 0,
    wildfireScore: 0,
    railJunctionScore: 0,
    tunnelScore: 0,
    outOfServiceAreaScore: 0,
    cellProvider: "Any",
    mileage: 0,
    duration: 0,
  },
  vulnScores2: {
    vulnScore: 0,
    cellCoverageScore: 0,
    delayScore: 0,
    popScore: 0,
    weatherScore: 0,
    incidentScore: 0,
    wildfireScore: 0,
    railJunctionScore: 0,
    tunnelScore: 0,
    outOfServiceAreaScore: 0,
    cellProvider: "Any",
    mileage: 0,
    duration: 0,
  },
  vulnScores3: {
    vulnScore: 0,
    cellCoverageScore: 0,
    delayScore: 0,
    popScore: 0,
    weatherScore: 0,
    incidentScore: 0,
    wildfireScore: 0,
    railJunctionScore: 0,
    tunnelScore: 0,
    outOfServiceAreaScore: 0,
    cellProvider: "Any",
    mileage: 0,
    duration: 0,
  },
};

const vulnScoresSlice = createSlice({
  name: "vulnScores",
  initialState,
  reducers: {
    setVulnScores: (state, action) => {
      try {
        state.vulnScores = action.payload;
      } catch (e) {
        alert("Failed to load vulnerability scores. Please try again.");
      }
    },
    setVulnScores2: (state, action) => {
      try {
        state.vulnScores2 = action.payload;
      } catch (e) {
        alert("Failed to load vulnerability scores. Please try again.");
      }
    },
    setVulnScores3: (state, action) => {
      try {
        state.vulnScores3 = action.payload;
      } catch (e) {
        alert("Failed to load vulnerability scores. Please try again.");
      }
    },
  },
});

export const { setVulnScores } = vulnScoresSlice.actions;
export const { setVulnScores2 } = vulnScoresSlice.actions;
export const { setVulnScores3 } = vulnScoresSlice.actions;

export default vulnScoresSlice.reducer;

export const getVulnScores = (state: any) => state.vulnScores.vulnScores;
export const getVulnScores2 = (state: any) => state.vulnScores.vulnScores2;
export const getVulnScores3 = (state: any) => state.vulnScores.vulnScores3;
